import '@builder.io/widgets';
import BuilderResource from './components/BuilderResource';
import registerBuilderComponents from '~/javascripts/utils/registerBuilderComponents';
import { builder } from '@builder.io/react';
import { render } from 'react-dom';

window.BuilderServerComponentsMarkup = {};

function initBuilder() {
  if (!window.BuilderEnabledPage) return;

  builder.init(window.BuilderApiKey);

  const BuilderServerComponents = {};

  document.querySelectorAll('[data-server-component]').forEach((el) => {
    const { builderId } = el.dataset;

    window.BuilderServerComponentsMarkup[builderId] = el.innerHTML;
  });

  window.AllBuilderServerComponents.forEach((component) => {
    BuilderServerComponents[component] = (props) => {
      const markup =
        window.BuilderServerComponentsMarkup[props.builderBlock.id];
      return <div dangerouslySetInnerHTML={{ __html: markup }} />;
    };
  });

  registerBuilderComponents(BuilderServerComponents);

  document.querySelectorAll('[data-builder-resource]').forEach(async (el) => {
    const {
      locale,
      builderResource,
      builderId,
      builderAdditionalData,
      isUkSite,
      isPreview,
    } = el.dataset;
    const content = await fetch(
      `/api/v1/builder/content?resourceName=${builderResource}&resourceId=${builderId}&locale=${locale}&isPreview=${isPreview}&additionalData=${builderAdditionalData}&isUkSite=${isUkSite}`
    ).then((response) => response.json());

    render(
      <BuilderResource
        content={content}
        locale={locale}
        resourceName={builderResource}
        theme={window.BuilderTheme}
      />,
      el.querySelector('[data-name]')
    );
  });
}

document.addEventListener('DOMContentLoaded', initBuilder);
