import { Builder } from '@builder.io/react';

const registerBuilderComponents = (BuilderServerComponents) => {
  Builder.registerComponent(BuilderServerComponents['DonateForm'], {
    name: 'Donate Form',
    inputs: [
      {
        name: 'campaignSlugOrId',
        type: 'string',
        friendlyName: 'Campaign Slug Or ID',
        helperText:
          'default: "general" for US, "general_uk" for UK. This controls the default configuration for the donation form & dictates what campaign any donations & subscriptions are associated with.',
      },
      {
        name: 'customNsIntegrationCodeFor1xDonations',
        type: 'string',
        friendlyName: 'Custom Netsuite Integration Code for 1x Donations',
      },
      {
        name: 'customNsIntegrationCodeForWps1xDonations',
        type: 'string',
        friendlyName: 'Custom Netsuite Integration Code for WPS 1x Donations',
      },
      {
        name: 'customNsIntegrationCodeForSubscriptionDonations',
        type: 'string',
        friendlyName:
          'Custom Netsuite Integration Code for Subscription Donations',
        helperText:
          'This will be used for ALL subscription donations, first and recurring, but only for monthly and not annual subscriptions',
      },
      {
        name: 'customXeroIntegrationCodeFor1xDonations',
        type: 'string',
        friendlyName: 'Custom Xero Integration Code for 1x Donations',
      },
      {
        name: 'customXeroIntegrationCodeForWps1xDonations',
        type: 'string',
        friendlyName: 'Custom Xero Integration Code for WPS 1x Donations',
      },
      {
        name: 'customXeroIntegrationCodeForSubscriptionDonations',
        type: 'string',
        friendlyName: 'Custom Xero Integration Code for Subscription Donations',
        helperText:
          'This will be used for ALL subscription donations, first and recurring, but only for monthly and not annual subscriptions',
      },
      {
        name: 'minAmount',
        type: 'number',
        defaultValue: '1',
        friendlyName: 'Minimum One Time Amount',
        helperText:
          'Set a specific minimum threshold for one time custom donation amounts. This only affects the "Other amount" field, so take care not to set a minimum that is higher than the preset options or it may create a confusing user experience.',
      },
      {
        name: 'subscriptionMinAmount',
        type: 'number',
        defaultValue: '5',
        friendlyName: 'Minimum Subscription Amount',
        helperText:
          'Set a specific minimum threshold for subscription custom donation amounts. This only affects the "Other amount" field, so take care not to set a minimum that is higher than the preset options or it may create a confusing user experience.',
        required: true,
        onChange: (options) => {
          if (isNaN(options.get('subscriptionMinAmount'))) {
            alert('This is a required field');
          }
        },
      },
      {
        name: 'showAmountInput',
        type: 'boolean',
        defaultValue: false,
        friendlyName: 'Show Amount Input Field?',
        helperText:
          'Show the single input field instead of the amount buttons (default: no).',
      },
      {
        name: 'showAmountInputWithAmountButtons',
        type: 'boolean',
        friendlyName: 'Show Amount Input With Amount Buttons?',
        helperText:
          'Show the amount input with mini amount buttons underneath (default: no).',
      },
      {
        name: 'showSubscriptionOption',
        type: 'boolean',
        defaultValue: false,
        friendlyName: 'Show Subscription Option?',
      },
      {
        name: 'acceptsIho',
        type: 'boolean',
        defaultValue: false,
        friendlyName: 'Accepts IHO?',
        helperText: 'Default: no.',
      },
      {
        name: 'acceptsChecks',
        type: 'boolean',
        defaultValue: false,
        friendlyName: 'Accepts Checks & Crypto?',
        helperText: 'Default: no.',
      },
      {
        name: 'defaultToggle',
        type: 'string',
        enum: ['monthly', 'one-time'],
      },
      {
        name: 'amountStepHeader',
        type: 'string',
        helperText:
          'Optional amount step header text to display instead of default ("Choose/Enter an amount to give")',
      },
      {
        name: 'skipAmountStep',
        type: 'boolean',
        friendlyName: 'Skip Amount Step?',
        helperText: 'Show payment step on load?',
      },
      {
        name: 'allowAmountEdit',
        type: 'boolean',
        friendlyName: 'Allow Amount Edit?',
        helperText:
          'Used with "Skip Amount Step?". Show the Edit Amount link on payment step. If "Skip Amount Step?" above is true, this defaults to false.',
      },
      {
        name: 'disablePaypal',
        type: 'boolean',
        friendlyName: 'Disable PayPal?',
        helperText: 'Remove PayPal as a payment option',
      },
      {
        name: 'customDefaultAmounts',
        type: 'list',
        subFields: [
          {
            name: 'oneTime',
            type: 'number',
            defaultValue: null,
          },
          {
            name: 'monthly',
            type: 'number',
            defaultValue: null,
          },
        ],
        onChange: (options) => {
          if (options.get('customDefaultAmounts').length > 1) {
            options.set(
              'customDefaultAmounts',
              options.get('customDefaultAmounts').slice(0, 1)
            );
            alert('Can only define one set of default amounts');
          }
        },
      },
      {
        name: 'customButtonAmounts',
        type: 'object',
        defaultValue: {},
        subFields: [
          {
            name: 'oneTime1',
            type: 'number',
          },
          {
            name: 'oneTime2',
            type: 'number',
          },
          {
            name: 'oneTime3',
            type: 'number',
          },
          {
            name: 'oneTime4',
            type: 'number',
          },
          {
            name: 'oneTimeHighlight',
            type: 'number',
          },
          {
            name: 'monthly1',
            type: 'number',
          },
          {
            name: 'monthly2',
            type: 'number',
          },
          {
            name: 'monthly3',
            type: 'number',
          },
          {
            name: 'monthly4',
            type: 'number',
          },
          {
            name: 'monthlyHighlight',
            type: 'number',
          },
        ],
      },
      {
        name: 'subscriptionUpsellConfig',
        type: 'list',
        subFields: [
          {
            name: 'minAmount',
            type: 'number',
            required: true,
          },
          {
            name: 'period',
            type: 'string',
            enum: ['monthly', 'annual'],
          },
          {
            name: 'percent',
            type: 'number',
          },
          {
            name: 'disableUpsell',
            type: 'boolean',
          },
          {
            name: 'options',
            type: 'object',
            defaultValue: {},
            subFields: [
              {
                name: 'headerText',
                type: 'string',
              },
              {
                name: 'subheaderText',
                type: 'string',
              },
              {
                name: 'labelText',
                type: 'string',
              },
              {
                name: 'submitButtonText',
                type: 'string',
              },
            ],
          },
        ],
        friendlyName: 'Subscription Upsell Config (1x => monthly/annual)',
        helperText:
          'See documentation: https://www.charitywater.org/admin/docs/components/donation_flows.md#via-builder',
      },
      {
        name: 'subscriptionUpsellConfigMonthlyToAnnual',
        type: 'list',
        subFields: [
          {
            name: 'minAmount',
            type: 'number',
            required: true,
          },
          {
            name: 'period',
            type: 'string',
            enum: ['annual'],
          },
          {
            name: 'percent',
            type: 'number',
          },
          {
            name: 'disableUpsell',
            type: 'boolean',
          },
          {
            name: 'options',
            type: 'object',
            defaultValue: {},
            subFields: [
              {
                name: 'headerText',
                type: 'string',
              },
              {
                name: 'subheaderText',
                type: 'string',
              },
              {
                name: 'labelText',
                type: 'string',
              },
              {
                name: 'submitButtonText',
                type: 'string',
              },
            ],
          },
        ],
        friendlyName: 'Subscription Upsell Config (monthly => annual)',
        helperText:
          'See documentation: https://www.charitywater.org/admin/docs/components/donation_flows.md#via-builder',
      },
      {
        name: 'referralUserId',
        type: 'string',
        friendlyName: 'Attribution User Id',
        helperText:
          'Maji UUID of the User who should receive attribution for donations on this page (ex: b88dfa24-206c-4f36-a9db-6dc22f0a5f67)',
      },
      {
        name: 'customBuilderThankYouPagePath',
        type: 'string',
        helperText:
          'Optional path of a page to render instead of the default thank you page for non-WPS donations (< $5k/£6k)',
        regex: {
          pattern: '^((?!http|/))',
          options: 'g',
          message: 'Should be a path (not full URL), without the leading "/"',
        },
      },
      {
        name: 'customBuilderWpsThankYouPagePath',
        type: 'string',
        helperText:
          'Optional path of a page to render instead of the default thank you page for WPS donations (>= $5k/£6k)',
        regex: {
          pattern: '^((?!http|/))',
          options: 'g',
          message: 'Should be a path (not full URL), without the leading "/"',
        },
      },
      {
        name: 'customInfositeOneTimeThankYouPageName',
        type: 'string',
        helperText:
          'Name of the custom Thank You Page entry in Infosite to use for 1x donations',
      },
      {
        name: 'customInfositeMonthlyThankYouPageName',
        type: 'string',
        helperText:
          'Name of the custom Thank You Page entry in Infosite to use for monthly donations',
      },
      {
        name: 'customInfositeWpsThankYouPageName',
        type: 'string',
        helperText:
          'Name of the custom Thank You Page entry in Infosite to use for WPS level donations (>= $5k/£6k)',
      },
      {
        name: 'customWelcomeJourneyId',
        type: 'string',
        helperText:
          'Iterable Journey Id to optionally send a custom welcome series INSTEAD of the default one. Maji is NOT responsible for this logic, this field is solely a pass-trough field for Iterable journeys to filter on.',
      },
      {
        name: 'customThankYouJourneyId',
        type: 'string',
        helperText:
          'Iterable Journey Id to optionally send a custom Thank you series for a 1x donation INSTEAD of the default series (receives same merge vars as default thank you mailer). Maji is NOT responsible for this logic, solely a pass-through field to Iterable',
      },
      {
        name: 'donateOneTimeCta',
        type: 'string',
        friendlyName: 'Donate One Time CTA',
        helperText:
          'Configures the "next step" button text on the amount step for one-time donations. The string "%{amount}" will be replaced with the donation form amount (e.g. "$100" or "£2,000").',
      },
      {
        name: 'donateMonthlyCta',
        type: 'string',
        friendlyName: 'Donate Monthly CTA',
        helperText:
          'Configures the "next step" button text on the amount step for monthly donations. The string "%{amount}" will be replaced with the donation form amount (e.g. "$100" or "£2,000").',
      },
      {
        name: 'monthlyToggleCtaText',
        type: 'code',
        friendlyName: 'Monthly Toggle CTA Text',
        helperText:
          'CTA block displayed below the Give Once / Monthly toggle on the first (amount) step of the donation form',
      },
      {
        name: 'hideWpsAmountButton',
        type: 'boolean',
        defaultValue: 'false',
        friendlyName: 'Hide WPS Amount Button',
      },
      {
        name: 'defaultSubscriptionPaymentMethodOnDesktopToBank',
        type: 'boolean',
        defaultValue: 'false',
        helperText:
          'Should the donation form default to the bank tab for subscriptions on desktop?',
      },
      {
        name: 'defaultSubscriptionPaymentMethodOnMobileToBank',
        type: 'boolean',
        defaultValue: 'false',
        helperText:
          'Should the donation form default to the bank tab for subscriptions on mobile?',
      },
      {
        name: 'amountStepTextBlock1xDonation',
        type: 'longText',
        friendlyName: '1x Amount Step Text Block',
        helperText:
          'Configures the text below submit button for a 1x donation. Ex: "Your donation will change lives all year long. 100% funds water projects."',
      },
      {
        name: 'amountStepTextBlockMonthly',
        type: 'longText',
        friendlyName: 'Monthly Amount Step Text Block',
        helperText:
          'Configures the text below submit button for a monthly donation. Ex: "Your monthly donation can give people clean water every year. 100% funds water projects."',
      },
      {
        name: 'landingPageCampaignTag',
        type: 'shortText',
        friendlyName: 'Landing Page Campaign Tag',
        helperText:
          'You can use this field in the Donation Onboarding Flow and/or Subscription Onboarding Flow journeys to customize the journey logic. For instance, if you fill this field out, you can use it to set up the journey logic to send donors giving through this donation form a campaign-specific thank you email, but then have them receive the rest of the normal onboarding journey.',
      },
      {
        name: 'eventKioskMode',
        type: 'boolean',
        defaultValue: false,
        friendlyName: 'Event Kiosk Mode',
        helperText:
          'If true, the donation form will not automatically log in a donor after a successful donation and will redirect to the custom builder thank you page specified above. This is useful for events where multiple donors will be using the same device.',
      },
    ],
  });

  Builder.registerComponent(BuilderServerComponents['LeadForm'], {
    name: 'Lead Form',
    inputs: [
      {
        name: 'offer',
        type: 'reference',
        friendlyName: 'Offer',
        helperText: 'What is the user signing up for?',
        model: 'lead-form-offer',
        required: true,
      },
      {
        name: 'place',
        type: 'string',
        defaultValue: 'page body',
        friendlyName: 'Place',
        helperText: 'Where on the page is the form?',
        enum: ['page body', 'footer'],
      },
      {
        name: 'mailingListsToJoin',
        type: 'object',
        defaultValue: {
          main_list: true,
          campaign_updates: false,
          donation_updates: false,
          the_drop: false,
        },
        friendlyName: 'Mailing Lists To Join',
        helperText:
          'Which mailing list(s) should the user be added to when submitting this form? (Note that The Drop is UK-only. This is handled on the back-end; No need to localize!)',
        subFields: [
          {
            name: 'main_list',
            type: 'boolean',
            defaultValue: true,
          },
          {
            name: 'campaign_updates',
            type: 'boolean',
            defaultValue: false,
          },
          {
            name: 'donation_updates',
            type: 'boolean',
            defaultValue: false,
          },
          {
            name: 'the_drop',
            type: 'boolean',
            defaultValue: false,
          },
        ],
      },
      {
        name: 'submitButtonText',
        type: 'string',
        defaultValue: 'Subscribe',
        friendlyName: 'Submit Button Text',
        helperText: 'Default: "Subscribe"',
      },
      {
        name: 'thankYouFlashMessage',
        type: 'string',
        friendlyName: 'Thank You Flash Message',
        helperText:
          'Custom message to appear in banner after successful form submission. (Note: Not shown if Thank You Page Path is provided.) Default: "You\'re signed up! You\'ll receive updates about the countries where we work, future giving opportunities, and more."',
      },
      {
        name: 'thankYouPagePath',
        type: 'string',
        friendlyName: 'Thank You Page Path',
        helperText:
          'Custom path of a page to direct to after form submission (e.g. /tiny-heroes-thank-you)',
      },
      {
        name: 'useInlineThankYouBlock',
        type: 'boolean',
        friendlyName: 'Use Inline Thank You Block',
        helperText:
          'Instead of a page reload, submitting the form swaps out the form for the "Inline Thank You Block Content" HTML (defined below)',
      },
      {
        name: 'inlineThankYouBlockContent',
        type: 'code',
        friendlyName: 'Inline Thank You Block Content',
        helperText:
          'HTML to display (in place of the lead form) after form submission, if "Use Inline Thank You Block" is set to true',
      },
      {
        name: 'sendDefaultWelcomeEmails',
        type: 'boolean',
        defaultValue: true,
        friendlyName: 'Send Default Welcome Emails',
        helperText:
          'Should the subscriber receive the default main list welcome series emails?',
      },
      {
        name: 'customWelcomeJourneyId',
        type: 'string',
        friendlyName: 'Custom Welcome Journey Id',
        helperText:
          'Iterable Journey ID to optionally send a custom welcome series instead of the Default one. Will only send if “Send Default Welcome Emails = No". Maji is not responsible for this logic, it is purely a pass through field to Iterable',
      },
      {
        name: 'offerJourneyId',
        type: 'string',
        friendlyName: 'Offer Journey Id',
        helperText:
          'Iterable Journey ID to send Lead Form offer specific content the user, in addition to welcome emails. Maji is not responsible for this logic, it is purely a pass through field to Iterable',
      },
      {
        name: 'showBirthday',
        type: 'boolean',
        defaultValue: false,
        friendlyName: 'Show Birthday',
        helperText: 'Will show the birthday dropdown on the form',
      },
      {
        name: 'requireBirthday',
        type: 'boolean',
        defaultValue: false,
        friendlyName: 'Require Birthday',
        helperText: 'Will make the birthday dropdown required, if shown',
      },
      {
        name: 'birthdayPledgeCheckbox',
        type: 'object',
        defaultValue: {
          show: false,
          offer: {
            '@type': '@builder.io/core:Reference',
            id: '6c7c16b21ff44ea68bee903696a6741c',
            model: 'lead-form-offer',
          },
          customWelcomeJourneyId: 'birthday-pledge',
        },
        friendlyName: 'Birthday Pledge Checkbox',
        helperText:
          'Show the checkbox to opt-in to pledge birthday and receive birthday emails',
        subFields: [
          {
            name: 'show',
            type: 'boolean',
            defaultValue: false,
          },
          {
            name: 'offer',
            type: 'reference',
            model: 'lead-form-offer',
            helperText: '**Required if show is true** Choose "birthday_pledge"',
          },
          {
            name: 'customWelcomeJourneyId',
            type: 'string',
            helperText: '**Required if show is true** Enter "birthday-pledge"',
          },
        ],
      },
      {
        name: 'showPhoneNumber',
        type: 'boolean',
        defaultValue: false,
        friendlyName: 'Show Phone Number',
        helperText: 'Will show the phone number field on the form (US only)',
      },
      {
        name: 'requirePhoneNumber',
        type: 'boolean',
        defaultValue: false,
        friendlyName: 'Require Phone Number',
        helperText: 'Will make the phone number field required, if shown',
      },
    ],
  });

  Builder.registerComponent(BuilderServerComponents['SpringMap'], {
    name: 'Spring Map',
    inputs: [
      {
        name: 'stepOneHeaderText',
        type: 'string',
        friendlyName: 'Step One Header Text',
        helperText: 'Header text above "Explore Spring impact in X countries"',
      },
    ],
  });

  Builder.registerComponent(BuilderServerComponents['CompletedProjectsMap'], {
    name: 'Completed Projects Map',
    inputs: [],
  });

  Builder.registerComponent(BuilderServerComponents['CampaignSearch'], {
    name: 'Campaign Search',
    inputs: [
      {
        name: 'showSearchField',
        type: 'boolean',
        defaultValue: true,
        friendlyName: 'Show Search Field',
        helperText: 'Show the search bar and pill filters?',
      },
      {
        name: 'header',
        type: 'string',
        defaultValue: 'Give to a campaign for clean water',
        friendlyName: 'Header',
      },
      {
        name: 'subheader',
        type: 'string',
        friendlyName: 'Subheader',
      },
      {
        name: 'overriddenTrendingCampaignId',
        type: 'string',
        friendlyName: 'Trending Campaign Override',
        helperText:
          'Enter the id of the campaign to override the default trending campaign',
      },
    ],
  });

  Builder.registerComponent(BuilderServerComponents['StickyBanner'], {
    name: 'Sticky Banner',
    inputs: [
      {
        name: 'bannerText',
        type: 'string',
        helperText: 'Text to display in the banner',
        defaultValue:
          'Fundraisers make it possible to give people the gift of clean water. Join them.',
      },
      {
        name: 'buttonText',
        type: 'string',
        helperText: 'Text to display in the button',
        defaultValue: 'Start a fundraiser',
      },
      {
        name: 'buttonLink',
        type: 'string',
        helperText: 'Path of the page to link to',
        defaultValue: '/campaigns/new',
      },
      {
        name: 'bannerBackgroundColor',
        type: 'string',
        helperText: 'Background color of the banner',
        defaultValue: '#222520',
      },
      {
        name: 'bannerTextColor',
        type: 'string',
        helperText: 'Color of the banner text',
        defaultValue: '#FFFFFF',
      },
      {
        name: 'buttonBackgroundColor',
        type: 'string',
        helperText: 'Background color of the button',
        defaultValue: '#FFCA0A',
      },
      {
        name: 'buttonTextColor',
        type: 'string',
        helperText: 'Color of the button text',
        defaultValue: '#222520',
      },
    ],
  });

  Builder.registerComponent(
    BuilderServerComponents['AWeekendForWaterPledgeForm'],
    {
      name: 'A Weekend For Water Pledge Form',
      inputs: [
        {
          name: 'thankYouPagePath',
          type: 'string',
          helperText:
            'Path of a Builder page to render as the Thank You Page after the form is submitted',
          regex: {
            pattern: '^(/)',
            options: 'g',
            message: 'Should be a path (not full URL), with the leading "/"',
          },
          required: true,
        },
      ],
    }
  );

  Builder.registerComponent(BuilderServerComponents['BrandPartnershipsForm'], {
    name: 'Brand Partnerships Form',
    inputs: [],
  });

  Builder.registerComponent(
    BuilderServerComponents['CampaignTemplateLeaderboard'],
    {
      name: 'Campaign Template Leaderboard',
      inputs: [
        {
          name: 'slug',
          type: 'string',
          displayName: 'Campaign Template Slug',
        },
      ],
    }
  );

  Builder.registerComponent(BuilderServerComponents['Eoyg2024Banner'], {
    name: 'EOYG 2024 Banner',
    inputs: [],
  });

  Builder.registerComponent(BuilderServerComponents['KioskDonateForm'], {
    name: 'Kiosk Donate Form',
    inputs: [
      {
        name: 'welcomeImage',
        type: 'file',
        required: true,
      },
      {
        name: 'welcomeText',
        type: 'richText',
        required: true,
      },
      {
        name: 'welcomeButtonText',
        type: 'string',
        required: true,
      },
      {
        name: 'availableDonationTypes',
        type: 'object',
        defaultValue: {},
        subFields: [
          {
            name: 'oneTime',
            type: 'boolean',
            defaultValue: true,
          },
          {
            name: 'monthly',
            type: 'boolean',
            defaultValue: true,
          },
          {
            name: 'wps',
            type: 'boolean',
            defaultValue: true,
          },
        ],
      },
      {
        name: 'oneTimeButtonAmounts',
        type: 'object',
        defaultValue: {},
        subFields: [
          {
            name: 'button1',
            type: 'number',
          },
          {
            name: 'button2',
            type: 'number',
          },
          {
            name: 'button3',
            type: 'number',
          },
          {
            name: 'button4',
            type: 'number',
          },
          {
            name: 'buttonHighlight',
            type: 'number',
          },
        ],
      },
      {
        name: 'monthlyButtonAmounts',
        type: 'object',
        defaultValue: {},
        subFields: [
          {
            name: 'button1',
            type: 'number',
          },
          {
            name: 'button2',
            type: 'number',
          },
          {
            name: 'button3',
            type: 'number',
          },
          {
            name: 'button4',
            type: 'number',
          },
          {
            name: 'buttonHighlight',
            type: 'number',
          },
        ],
      },
      {
        name: 'availableWpsCountryRestrictions',
        type: 'list',
        subFields: [
          {
            name: 'countryCode',
            type: 'string',
          },
          {
            name: 'countryName',
            type: 'string',
          },
        ],
      },
      {
        name: 'thankYouImage',
        type: 'file',
        required: true,
      },
      {
        name: 'landingPageCampaignTag',
        type: 'shortText',
        friendlyName: 'Landing Page Campaign Tag',
        helperText:
          'You can use this field in the Donation Onboarding Flow and/or Subscription Onboarding Flow journeys to customize the journey logic. For instance, if you fill this field out, you can use it to set up the journey logic to send donors giving through this donation form a campaign-specific thank you email, but then have them receive the rest of the normal onboarding journey.',
      },
    ],
  });

  Builder.registerComponent(BuilderServerComponents['TakeAction'], {
    name: 'Take Action',
    inputs: [
      {
        name: 'step1HeaderText',
        type: 'string',
        friendlyName: 'Step 1 Header',
      },
      {
        name: 'step1ButtonText',
        type: 'string',
        friendlyName: 'Step 1 Button Text',
      },
      {
        name: 'step2HeaderText',
        type: 'string',
        friendlyName: 'Step 2 Header Text',
      },
      {
        name: 'block1',
        type: 'object',
        defaultValue: {},
        subFields: [
          {
            name: 'text',
            type: 'string',
          },
          {
            name: 'icon',
            type: 'string',
            helperText:
              'URL (e.g. https://...) of the icon to appear on block 1',
          },
          {
            name: 'action1',
            type: 'object',
            defaultValue: {},
            subFields: [
              {
                name: 'image',
                type: 'string',
                friendlyName: 'Image',
                helperText:
                  'URL (e.g. https://...) of the image for action 1 after selecting block 1',
              },
              {
                name: 'text',
                type: 'string',
                friendlyName: 'CTA Text',
                helperText: 'CTA text for action 1 after selecting block 1',
              },
              {
                name: 'path',
                type: 'string',
                friendlyName: 'CTA Path',
                helperText:
                  'Path (e.g. /donate) for action 1 after selecting block 1',
              },
            ],
          },
          {
            name: 'action2',
            type: 'object',
            defaultValue: {},
            subFields: [
              {
                name: 'image',
                type: 'string',
                friendlyName: 'Image',
                helperText:
                  'URL (e.g. https://...) of the image for action 2 after selecting block 1',
              },
              {
                name: 'text',
                type: 'string',
                friendlyName: 'CTA Text',
                helperText: 'CTA text for action 2 after selecting block 1',
              },
              {
                name: 'path',
                type: 'string',
                friendlyName: 'CTA Path',
                helperText:
                  'Path (e.g. /donate) for action 2 after selecting block 1',
              },
            ],
          },
          {
            name: 'action3',
            type: 'object',
            defaultValue: {},
            subFields: [
              {
                name: 'image',
                type: 'string',
                friendlyName: 'Image',
                helperText:
                  'URL (e.g. https://...) of the image for action 3 after selecting block 1',
              },
              {
                name: 'text',
                type: 'string',
                friendlyName: 'CTA Text',
                helperText: 'CTA text for action 3 after selecting block 1',
              },
              {
                name: 'path',
                type: 'string',
                friendlyName: 'CTA Path',
                helperText:
                  'Path (e.g. /donate) for action 3 after selecting block 1',
              },
            ],
          },
        ],
      },
      {
        name: 'block2',
        type: 'object',
        defaultValue: {},
        subFields: [
          {
            name: 'text',
            type: 'string',
          },
          {
            name: 'icon',
            type: 'string',
            helperText:
              'URL (e.g. https://...) of the icon to appear on block 2',
          },
          {
            name: 'action1',
            type: 'object',
            defaultValue: {},
            subFields: [
              {
                name: 'image',
                type: 'string',
                friendlyName: 'Image',
                helperText:
                  'URL (e.g. https://...) of the image for action 1 after selecting block 2',
              },
              {
                name: 'text',
                type: 'string',
                friendlyName: 'CTA Text',
                helperText: 'CTA text for action 1 after selecting block 2',
              },
              {
                name: 'path',
                type: 'string',
                friendlyName: 'CTA Path',
                helperText:
                  'Path (e.g. /donate) for action 1 after selecting block 2',
              },
            ],
          },
          {
            name: 'action2',
            type: 'object',
            defaultValue: {},
            subFields: [
              {
                name: 'image',
                type: 'string',
                friendlyName: 'Image',
                helperText:
                  'URL (e.g. https://...) of the image for action 2 after selecting block 2',
              },
              {
                name: 'text',
                type: 'string',
                friendlyName: 'CTA Text',
                helperText: 'CTA text for action 2 after selecting block 2',
              },
              {
                name: 'path',
                type: 'string',
                friendlyName: 'CTA Path',
                helperText:
                  'Path (e.g. /donate) for action 2 after selecting block 2',
              },
            ],
          },
          {
            name: 'action3',
            type: 'object',
            defaultValue: {},
            subFields: [
              {
                name: 'image',
                type: 'string',
                friendlyName: 'Image',
                helperText:
                  'URL (e.g. https://...) of the image for action 3 after selecting block 2',
              },
              {
                name: 'text',
                type: 'string',
                friendlyName: 'CTA Text',
                helperText: 'CTA text for action 3 after selecting block 2',
              },
              {
                name: 'path',
                type: 'string',
                friendlyName: 'CTA Path',
                helperText:
                  'Path (e.g. /donate) for action 3 after selecting block 2',
              },
            ],
          },
        ],
      },
      {
        name: 'block3',
        type: 'object',
        defaultValue: {},
        subFields: [
          {
            name: 'text',
            type: 'string',
          },
          {
            name: 'icon',
            type: 'string',
            helperText:
              'URL (e.g. https://...) of the icon to appear on block 3',
          },
          {
            name: 'action1',
            type: 'object',
            defaultValue: {},
            subFields: [
              {
                name: 'image',
                type: 'string',
                friendlyName: 'Image',
                helperText:
                  'URL (e.g. https://...) of the image for action 1 after selecting block 3',
              },
              {
                name: 'text',
                type: 'string',
                friendlyName: 'CTA Text',
                helperText: 'CTA text for action 1 after selecting block 3',
              },
              {
                name: 'path',
                type: 'string',
                friendlyName: 'CTA Path',
                helperText:
                  'Path (e.g. /donate) for action 1 after selecting block 3',
              },
            ],
          },
          {
            name: 'action2',
            type: 'object',
            defaultValue: {},
            subFields: [
              {
                name: 'image',
                type: 'string',
                friendlyName: 'Image',
                helperText:
                  'URL (e.g. https://...) of the image for action 2 after selecting block 3',
              },
              {
                name: 'text',
                type: 'string',
                friendlyName: 'CTA Text',
                helperText: 'CTA text for action 2 after selecting block 3',
              },
              {
                name: 'path',
                type: 'string',
                friendlyName: 'CTA Path',
                helperText:
                  'Path (e.g. /donate) for action 2 after selecting block 3',
              },
            ],
          },
          {
            name: 'action3',
            type: 'object',
            defaultValue: {},
            subFields: [
              {
                name: 'image',
                type: 'string',
                friendlyName: 'Image',
                helperText:
                  'URL (e.g. https://...) of the image for action 3 after selecting block 3',
              },
              {
                name: 'text',
                type: 'string',
                friendlyName: 'CTA Text',
                helperText: 'CTA text for action 3 after selecting block 3',
              },
              {
                name: 'path',
                type: 'string',
                friendlyName: 'CTA Path',
                helperText:
                  'Path (e.g. /donate) for action 3 after selecting block 3',
              },
            ],
          },
        ],
      },
    ],
  });

  Builder.registerComponent(BuilderServerComponents['VideoAskWidget'], {
    name: 'VideoAsk Widget',
    inputs: [
      {
        name: 'videoId',
        type: 'string',
        helperText: 'The share ID of the VideoAsk video',
      },
      {
        name: 'widgetText',
        type: 'string',
        helperText: 'The text to display on the widget',
      },
      {
        name: 'widgetPosition',
        type: 'string',
        enum: ['bottom-left', 'bottom-right'],
        helperText: 'The position of the widget on the page',
      },
      {
        name: 'widgetBackgroundColor',
        type: 'string',
      },
      {
        name: 'widgetType',
        type: 'string',
        enum: [
          'VideoThumbnailSmall',
          'VideoThumbnailExtraLarge',
          'VideoThumbnailWindow',
          'VideoThumbnailWindowTall',
        ],
        defaultValue: 'VideoThumbnailSmall',
        helperText: 'The type of widget to display',
      },
    ],
  });

  Builder.registerComponent(BuilderServerComponents['Wwd2024Quiz'], {
    name: 'WWD 2024 Quiz',
    inputs: [
      {
        name: 'intro',
        type: 'object',
        subFields: [
          {
            name: 'headerText',
            type: 'string',
          },
          {
            name: 'subHeaderText',
            type: 'string',
          },
          {
            name: 'buttonText',
            type: 'string',
          },
          {
            name: 'backgroundColor',
            type: 'color',
          },
        ],
      },
      {
        name: 'questions',
        type: 'list',
        subFields: [
          {
            name: 'optionAText',
            type: 'string',
          },
          {
            name: 'optionATextColor',
            type: 'color',
          },
          {
            name: 'optionAColor',
            type: 'color',
          },
          {
            name: 'optionBText',
            type: 'string',
          },
          {
            name: 'optionBTextColor',
            type: 'color',
          },
          {
            name: 'optionBColor',
            type: 'color',
          },
          {
            name: 'resultHeader',
            type: 'string',
          },
        ],
      },
      {
        name: 'lastQuestion',
        type: 'object',
        subFields: [
          {
            name: 'optionAText',
            type: 'string',
          },
          {
            name: 'optionAImage',
            type: 'file',
          },
          {
            name: 'optionAColor',
            type: 'color',
          },
          {
            name: 'optionAResultText',
            type: 'string',
          },
          {
            name: 'optionBText',
            type: 'string',
          },
          {
            name: 'optionBImage',
            type: 'file',
          },
          {
            name: 'optionBColor',
            type: 'color',
          },
          {
            name: 'optionBResultText',
            type: 'string',
          },
        ],
      },
    ],
  });

  Builder.registerComponent(BuilderServerComponents['WwdImpactSlider'], {
    name: 'WWD Impact Slider',
    inputs: [],
  });

  Builder.registerComponent(BuilderServerComponents['WwdSleepForWaterSlider'], {
    name: 'WWD Sleep For Water Slider',
    inputs: [],
  });
};

export default registerBuilderComponents;
