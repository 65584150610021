import renderComponentFromEl from '~/javascripts/utils/renderComponentFromEl';

const elementInBehaviorBasedModal = (el) =>
  $(el)
    .parents()
    .toArray()
    .map((p) => p.id)
    .filter((id) => String(id).trim())
    .find((id) => $(`[href="#${id}"][data-behavior="Modal"]`).length);

const renderComponents = (event) => {
  if (event.type == 'DOMContentLoaded' && window.BuilderEnabledPage) {
    return;
  }

  let elements;

  if (event.type == 'ProfileFeedLoaded') {
    elements = document.querySelectorAll(
      '.activity-feed-container [data-component]'
    );
  } else {
    elements = document.querySelectorAll('[data-component]');
  }

  Array.from(elements).forEach((el) => {
    if (elementInBehaviorBasedModal(el)) return;
    renderComponentFromEl(el);
  });
};

document.addEventListener('DOMContentLoaded', renderComponents);
document.addEventListener('BuilderContentLoaded', renderComponents);
