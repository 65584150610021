import balanceText from '~/javascripts/utils/balanceText';

const attachedElements = new WeakSet();

const attachBehavior = (module, el) => {
  module.default($(el));
};

const modules = import.meta.glob('~/javascripts/behaviors/**/*.jsx');

const attachBehaviors = (event) => {
  const elements = document.querySelectorAll('[data-behavior]');

  Array.from(elements).forEach((el) => {
    if (attachedElements.has(el)) return;

    const behaviors = el.dataset.behavior.split(' ');

    behaviors.forEach((behavior) => {
      if (
        $('body').hasClass('builder-page') &&
        event.type == 'DOMContentLoaded' &&
        behavior == 'Modal'
      ) {
        return;
      }
      attachedElements.add(el);
      modules[`/javascripts/behaviors/${behavior}.jsx`]().then((module) => {
        attachBehavior(module, el);
      });
    });
  });
};

document.addEventListener('DOMContentLoaded', (event) => {
  balanceText();
  attachBehaviors(event);
});
document.addEventListener('ModalOpened', attachBehaviors);
document.addEventListener('PaginationLoaded', attachBehaviors);
document.addEventListener('BuilderContentLoaded', (event) => {
  balanceText();
  attachBehaviors(event);
});
